import React from 'react';
import Container from '../Layouts/Container';
import { Link } from 'react-router-dom';

const Header = ({ className }: any) => {
  return (
    <header className={['shadow-md py-3 px-3', className].join(' ')}>
      <Container className={'flex flex-row justify-between items-center'}>
        <Link to={'/'} className={''}>
          <img src="/images/logo.png" alt="Sodexo Logo" className={'h-8 -mt-1'} />
        </Link>
      </Container>
    </header>
  );
};

export default Header;
