import React, { useEffect, useState } from 'react';
import Container from '../components/Layouts/Container';
import { useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet';
import FeedbackForm from '../components/Forms/FeedbackForm';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useNavigate } from 'react-router-dom';

const Homepage = () => {
  const urlHash = useLocation().pathname.substring(1);
  const WS_BASE_URL = "wss://" + process.env.REACT_APP_SERVER + "/socket";

  const [socketUrl, setSocketUrl] = useState(WS_BASE_URL + "/" + urlHash + "/");
  const [messageHistory, setMessageHistory] = useState<string[]>([]);
  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const [successWs, setSuccessWs] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (lastMessage !== null) {
      console.log(lastMessage);
    }
  }, [lastMessage, setMessageHistory, messageHistory]);

  useEffect(() => {
    if (readyState === ReadyState.CLOSED) {
      if (successWs === false) {
        navigate('/expired-link', { replace: true });
      }
      // @todo: it was opened before, so it is not expired but closed
    } else {
      if (readyState === ReadyState.OPEN) {
        setSuccessWs(true);
      }
    }
  }, [readyState]);

  // @todo: send sendMessage; with list of messages (object?)
  // these messages are used for green-ticking

  return (
    <>
      <Helmet>
        <title>Sodexo - Feedback</title>
      </Helmet>
      <Container>
        <FeedbackForm sendMessage={sendMessage}/>
      </Container>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </>
  );
};

export default Homepage;
