import React from 'react';
import Container from '../components/Layouts/Container';
import { Helmet } from 'react-helmet';
import ThankYouIllustration from '../components/Illustrations/ThankYouIlustration';
import { useLocation } from 'react-router-dom';

const ThankYou = () => {
  const location = useLocation();
  const rating = location.state.rating;

  // @note: it is possible to switch text according to rating -1; 1-5

  return (
    <div>
      <Helmet>
        <title>Sodexo - Děkujeme</title>
      </Helmet>
      <Container>
        <div className={'m-auto table mt-6'}>
          <ThankYouIllustration />
        </div>

        <h1 className={'mt-4 font-medium text-blue text-center'}>Děkujeme za vaši zpětnou vazbu</h1>
        <p className={'text-center table m-auto text-xs mt-3 text-blue'}>
          Vaše zpětná vazba je pro nás velmi důležitá, <br />
          protože díky ní můžeme vylepšovat naše služby
        </p>

      </Container>
    </div>
  );
};

export default ThankYou;
