import React from 'react';
import { Helmet } from 'react-helmet';
import { Outlet } from 'react-router-dom';

import Header from '../Navigation/Header';

const BaseLayout = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sodexo</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <Header />

      <Outlet />
    </>
  );
};

export default BaseLayout;
